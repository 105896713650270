body {
    font-family: Roboto,sans-serif;
    color: #58595b;
    line-height: 1.8
}

.topheader {
    width: 100%;
    background: #4fa3c3;
    background: linear-gradient(#4fa3c3,#fff)
}

.topheaderalign {
    padding-top: 50px
}

.topnameinlineblock .media-body {
    padding-top: 10px
}

.media-body.topline {
    padding-top: 13px;
    padding-left: 13px
}

.topnameinlineblock {
    width: 25%
}

.topinlineblock,.topnameinlineblock {
    display: inline-block;
    padding-top: 13px
}

.topinlineblock {
    width: 38%
}

.topphoneinlineblock {
    width: 28%;
    display: inline-block
}

a.interactive {
    background: url(../public/icon-fb.png) 0 no-repeat;
    height: 40px;
    width: 40px;
    display: inline-block;
    text-decoration: none;
    vertical-align: text-center
}

a.interactive:hover {
    background: url(../public/icon-fb-hover.png) 0 no-repeat
}

a {
    color: #58595b
}

a:hover {
    color: #4fa3c3
}

.navbar-default {
    background-color: transparent;
    border-color: transparent
}

.navbar-nav {
    margin-top: 40px
}

.logo {
    margin-left: -60px
}

.navbar-default .navbar-nav>li>a.active,.navbar-default .navbar-nav>li>a.active:focus,.navbar-default .navbar-nav>li>a.active:hover{
    background-color: #4fa3c3;
    border-color: transparent;
    color: #fff!important;
}
.messagesend{margin-top:20px;}
.navbar-default .navbar-nav>li>a {
    color: #58595b;
    font-size: 16px;
    padding: 10px 23px
}

.navbar-default .navbar-nav>li>a:hover {
    color: #fff
}

.navbar>.container-fluid .navbar-brand,.navbar>.container .navbar-brand {
    margin-left: 20px
}

.navbar-default .navbar-nav>li>a:focus {
    color: #58595b
}

#bannersect {
    background-image: url(../public/bg-banner.jpg);
    background-size: cover
}

.lightbluebox {
    background-color: #4fa3c3;
    opacity: .7;
    margin-left: -46px;
    padding: 30% 10% 5%;
    min-height: 500px
}

.lightbluebox h2 {
    color: #fff;
    line-height: 1.4
}

.learnmorebutton {
    display: block;
    background-color: #fff;
    color: #4fa3c3;
    padding: 15px;
    margin-bottom: 20px;
    width: 185px
}

.learnmorebutton:hover {
    display: block;
    background-color: #4fa3c3;
    color: #fff
}

.contactusbutton {
    display: block;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 15px;
    width: 185px
}

.contactusbutton:hover {
    display: block;
    background-color: #fff;
    color: #4fa3c3
}

a.contactusbutton,a.learnmorebutton {
    text-decoration: none
}

#vicsolargate h2 {
    padding: 30px 0 20px;
    color: #4fa3c3
}

#vicsolargate h3 {
    padding: 15px 0
}

#solargates h2 {
    color: #4fa3c3
}

.marginauto {
    margin: 0 auto
}

.featuring {
    margin-bottom: 20px
}

.proudlypresents {
    background-color: #ecf0f0;
    padding-bottom: 40px;
    padding-top: 40px;
}

.colorhead {
    padding: 30px 0
}

.greenhead h2 {
    color: #8dc63f
}

.darkyellowhead h2 {
    color: #ef812e
}

.yellowhead h2 {
    color: #f8bf2b
}

.bluehead h2 {
    color: #4fa3c3
}

.gallerybox {
    padding: 40px 0
}

.gallerybox img {
    width: 160px;
    height: 128px;
    margin-bottom: 10px
}

.gallerybox a {
    padding-right: 10px;
    padding-bottom: 10px
}

.spacer {
    margin-left: 10px
}

a.greenbutton {
    background-color: #8dc63f;
    padding: 10px 20px;
    text-align: center;
    color: #fff;
    display: block;
    margin: 20px 0;
    font-weight: 700;
    font-size: 16px
}

a.greenbutton:hover {
    background-color: #2bb673;
    text-decoration: none
}

.anchorprop {
    padding: 5px 0;
    width: 200px;
    display: inline-block;
    text-decoration: none;
    vertical-align: text-center;
    text-align: center
}

.carousel-indicators li {
    border-radius: 0;
    margin: 0 10px
}

.carousel-indicators .active {
    margin: 0 10px
}

.anchorprop:hover {
    text-decoration: none
}

.anchoricon {
    width: 40px;
    height: 40px;
    margin-left: -10px
}

a.singlegatefeature {
    background-color: #009444;
    color: #fff;
    float: right
}

a.singlegatefeature:hover {
    background-color: #8dc63f
}

a.singlegateoption {
    border: 1px solid #009444;
    background-color: #fff;
    color: #009444
}

a.singlegateoption:hover {
    border: 1px solid #fff;
    background-color: #8dc63f;
    color: #fff
}

a.doublegatefeature {
    background-color: #4fa3c3;
    color: #fff;
    float: right
}

a.doublegatefeature:hover {
    background-color: #3a7c94
}

a.doublegateoption {
    border: 1px solid #4fa3c3;
    background-color: #fff;
    color: #4fa3c3
}

a.doublegateoption:hover {
    border: 1px solid #fff;
    background-color: #3a7c94;
    color: #fff
}

a.slidinggatefeature {
    background-color: #f8bf2b;
    color: #fff;
    float: right
}

a.slidinggatefeature:hover {
    background-color: #ef812e;
    color: #fff
}

a.slidinggateoption {
    border: 1px solid #f8bf2b;
    background-color: #fff;
    color: #f8bf2b
}

a.slidinggateoption:hover {
    border: 1px solid #fff;
    background-color: #ef812e;
    color: #fff
}

.featuretext {
    padding: 0 0 50px
}

.featurebox,.powerbox {
    display: block;
    margin-bottom: 30px
}

.powerbox h3 {
    color: #2bb673
}

.featurebox h5 {
    color: #3a7c94;
    padding-top: 25px
}

.powercontentbox {
    padding: 50px 0
}

.alertbox {
    padding: 5px;
    background-color: #ef812e;
    text-align: center;
    color: #fff;
    margin: 25px 0
}

.alertbox img {
    margin-right: 10px
}

.alertinline {
    display: inline-block;
    vertical-align: middle
}

.yellowsubhead {
    color: #ef812e;
    font-weight: 700;
    margin-top: 25px;
    text-align: center
}

.optionblock {
    margin-bottom: 50px
}

.optionblock .media-body {
    padding-left: 10px
}

.extraplus {
    margin-right: 15px;
    color: #ef812e;
    display: inline-block;
    vertical-align: top
}

.margin30 {
    margin-bottom: 30px
}

.extraplusright {
    display: inline-block;
    width: 95%
}

.installationheader {
    color: #4fa3c3;
    padding: 30px 0;
    text-align: center
}

.contactusinline {
    display: inline-block;
    vertical-align: middle;
    padding: 8px 0
}

.copyright {
    padding: 40px 0
}

.copyright a {
    color: #4fa3c3
}

.copyright a:hover {
    color: #3a7c94;
    text-decoration: none
}

.contactusinline img {
    margin-right: 15px
}

.bottomfb a.interactive {
    margin-top: 10px
}

#contactform input[type=email],#contactform input[type=text],#contactform input[type=tel] {
    border: 1px solid #4fa3c3;
    padding: 12px;
    width: 85%;
    margin-bottom:8px;
}

textarea#additionalinfo {
    width: 85%;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #4fa3c3
}

.checkborder {
    border: 1px solid #4fa3c3;
    padding: 5px 7px;
    margin-right: 10px
}

.cbcontent {
    margin-right: 15px
}

.tbmargin {
    margin-top: 15px;
    margin-bottom: 15px
}

input[type=checkbox] {
    -webkit-appearance: initial;
    appearance: initial;
    background: gray;
    width: 12px;
    height: 12px;
    border: none;
    position: relative
}

.help-block {
    color: red
}

.fullwidth {
    width: 100%
}

.cinline {
    display: inline-block;
    vertical-align: middle
}

#contactform input[type=text]#fileupload {
    display: inline-block;
    width: 60%
}

#uploadfile {
    visibility: hidden
}

.browsebutton {
    background: #4fa3c3;
    color: #fff;
    padding: 10px;
    display: block;
    width: 130px;
    text-align: center;
    padding: 13px;
    border: 1px solid #4fa3c3
}

a.browsebutton:active,a.browsebutton:focus,a.browsebutton:hover,a.browsebutton:visited {
    color: #fff;
    text-decoration: none
}

.submitbutton {
    background: #4fa3c3;
    color: #fff;
    padding: 10px;
    display: block;
    width: 130px;
    text-align: center;
    border: 0
}

input[type=checkbox] {
    background: #fff;
    border: 1px dotted #fff
}

input[type=checkbox]:checked {
    background: #fff;
    border: 1px dotted #4fa3c3;
    color: #4fa3c3
}

input[type=checkbox]:focus,input[type=checkbox]:visited {
    border: none;
    outline: none
}

input[type=checkbox]:checked:after {
    content: "\2713";
    background: #4fa3c3;
    color: #4fa3c3;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid #4fa3c3;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.cgcontent {
    background-color: rgba(141,198,63,.2)
}

.margin20 {
    margin-top: 20px
}

.customsubheader {
    color: #009444
}

.innercopyright {
    background-color: #ebf5fc
}

.marginb20 {
    margin-bottom: 20px
}

.margint30 {
    margin-top: 30px
}

.margint50 {
    margin-top: 50px
}

.padding20 {
    padding: 0 20px
}

.innerpage .greenbutton {
    padding: 20px
}

.dycontent {
    background-color: #fbd738
}

.diysubheader {
    color: #b64c15;
    font-size: 20px
}

a.diyredbutton {
    background-color: #ef812e;
    padding: 20px;
    text-align: center;
    color: #fff;
    display: block;
    margin: 20px 0;
    font-weight: 700;
    font-size: 18px
}

a.diyredbutton:hover {
    background-color: #b64c15;
    text-decoration: none
}

#section6 {
    background-color: #ebf5fc
}

#section8 {
    background-color: rgba(251,215,56,.7)
}

#section10 {
    background-color: #ecf0f0
}

#section13 {
    background-color: #ebf5fc
}

.carousel-control.left,.carousel-control.right {
    background-image: linear-gradient(90deg,transparent 0,transparent)
}

#doubleCarousel,#myCarousel,#slidingCarousel {
    min-height: 130px;
    padding-bottom: 30px
}

#menuBlock {
    z-index: 1;
    width: 100%
}

.menuBlockback {
    background: #4fa3c3;
    background: linear-gradient(#4fa3c3,#fff)
}

.plusClick {
    cursor: pointer;
    color: #3a7c94
}

.optionPlusClick {
    cursor: pointer;
    color: #ef812e
}

.mapcontrol {
    position: relative;
    border: 0
}

.i4ewOd-pzNkMb-tJHJj {
    display: none
}

.carousel-control {
    width: auto
}

.carousel-control .glyphicon-chevron-left,.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next,.carousel-control .icon-prev {
    top: 41%
}

.carousel-control .glyphicon-chevron-left,.carousel-control .icon-prev {
    margin-left: 2px
}

.carousel-control .glyphicon-chevron-right,.carousel-control .icon-next {
    margin-right: 2px
}

.contactiframe {
    border: 0;
    width: 100%;
    min-height: 680px;
    max-height: 1200px;
}

.error {
    border: 1px solid red!important
}

@media(min-width: 200px) and (max-width:1000px) {
    .navbar-toggle {
        display:block
    }

    .navbar-collapse.collapse {
        display: none!important
    }

    .navbar-collapse.in {
        display: block!important
    }

    .logo {
        margin-left: auto
    }

    .topheaderalign {
        padding-top: 10px
    }

    .navbar-header {
        float: right
    }

    .navbar-nav {
        float: inherit
    }

    .navbar-nav>li {
        float: inherit;
        width: 65%
    }

    .lightbluebox {
        margin-left: 0;
        min-height: 100px;
        padding: 5% 0 4% 5%
    }

    .navbar-default {
        margin-top: -63px
    }

    #navbar-collapse-1 {
        margin-top: 63px
    }

    #menuBlock {
        position: relative
    }

    .anchorprop {
        width: 100%;
        padding: 5px 20px
    }

    a.diyredbutton {
        margin: 0
    }
}

@media(min-width: 1200px) {
    .container {
        width:1075px
    }
}

@media(min-width: 200px) and (max-width:566px) {
    .col-xs-5.mobctrl,.col-xs-7.mobctrl {
        width:100%
    }

    .topinlineblock,.topnameinlineblock,.topphoneinlineblock {
        text-align: left;
        width: 100%
    }

    .topnameinlineblock .media-object,.topphoneinlineblock .media-object {
        display: none
    }

    .topinlineblock .media-body.topline {
        width: 100%;
        display: block;
        padding-bottom: 15px
    }

    .topinlineblock .media-right {
        width: 100%;
        display: block
    }

    .topinlineblock {
        padding-top: 0
    }

    .media-body.topline {
        padding: 0
    }
}

@media(min-width: 567px) and (max-width:599px) {
    #myHomeCarousel img {
        height:218px
    }

    .lightbluebox {
        margin-left: -30px;
        min-height: 218px
    }

    .lightbluebox h2 {
        font-size: 18px;
        margin-top: 5px
    }

    .contactusbutton,.learnmorebutton {
        padding: 8px
    }

    .topnameinlineblock {
        width: 20%
    }

    .topnameinlineblock img {
        display: none
    }

    .topphoneinlineblock {
        width: 22%
    }

    .topphoneinlineblock img {
        display: none
    }

    .topinlineblock {
        width: 44%
    }

    .topinlineblock img {
        display: none
    }
}

@media(min-width: 600px) and (max-width:639px) {
    .lightbluebox {
        margin-left:-30px
    }

    .lightbluebox h2 {
        font-size: 22px;
        margin-top: 5px
    }

    .contactusbutton,.learnmorebutton {
        padding: 8px
    }

    .topnameinlineblock {
        width: 20%
    }

    .topnameinlineblock img {
        display: none
    }

    .topphoneinlineblock {
        width: 22%
    }

    .topphoneinlineblock img {
        display: none
    }

    .topinlineblock {
        width: 44%
    }

    .topinlineblock img {
        display: none
    }
}

@media(min-width: 640px) and (max-width:666px) {
    #myHomeCarousel img {
        height:250px
    }

    .lightbluebox {
        margin-left: -30px;
        min-height: 250px
    }

    .lightbluebox h2 {
        font-size: 22px;
        margin-top: 5px
    }

    .contactusbutton,.learnmorebutton {
        padding: 11px
    }

    .topnameinlineblock {
        width: 24%
    }

    .topphoneinlineblock {
        width: 26%
    }

    .topinlineblock {
        width: 38%
    }
}

@media(min-width: 667px) and (max-width:700px) {
    #myHomeCarousel img {
        height:263px
    }

    .lightbluebox {
        margin-left: -30px;
        min-height: 263px
    }

    .lightbluebox h2 {
        font-size: 25px;
        margin-top: 8px
    }

    .contactusbutton,.learnmorebutton {
        padding: 10px
    }
}

@media(min-width: 701px) and (max-width:731px) {
    .lightbluebox {
        margin-left:-30px
    }

    .lightbluebox h2 {
        font-size: 28px;
        margin-top: 5px
    }

    .contactusbutton,.learnmorebutton {
        padding: 15px
    }
}

@media(min-width: 732px) and (max-width:737px) {
    #myHomeCarousel img {
        height:294px
    }

    .lightbluebox {
        min-height: 294px;
        margin-left: -30px
    }

    .lightbluebox h2 {
        font-size: 23px;
        margin-top: 5px
    }

    .contactusbutton,.learnmorebutton {
        padding: 15px
    }
}

@media(min-width: 738px) and (max-width:1000px) {
    .lightbluebox {
        margin-left:-30px;
        min-height: 300px
    }

    .lightbluebox h2 {
        font-size: 27px
    }
}

@media(min-width: 567px) and (max-width:1000px) {
    .logo {
        width:75%
    }

    .topinlineblock .media-right {
        padding-right: 15px
    }
}

